html, body {
  height: 100%;
}
body {
  min-height: 100%;
}

#root {
  background-color: #f9f9f9;
  display: flex;
  height: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
}